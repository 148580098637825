import * as React from "react"
import { useContext, useEffect } from "react"
import { Transition } from "framer-motion"
import { LayoutIdContext, GetLayoutId } from "./LayoutIdContext"

/**
 * @internal
 */
export interface MagicMotionContextProps {
    enabled?: boolean
    transition?: Transition
    layoutOrder: number
    isPresent: false | undefined
    getLayoutId: (args: GetLayoutId) => string | null
    magicDependency?: boolean
}

/**
 * @internal
 */
export const MagicMotionContext = React.createContext<MagicMotionContextProps>({
    enabled: false,
    getLayoutId: args => null,
    magicDependency: true,
    layoutOrder: 0,
    isPresent: undefined,
})

/**
 * @internal
 */
export function MagicMotionProvider({
    enabled,
    magicDependency,
    layoutOrder,
    areMagicMotionLayersPresent,
    children,
}: {
    enabled?: boolean
    magicDependency?: boolean
    layoutOrder: number
    areMagicMotionLayersPresent: false | undefined
    children: React.ReactNode
    onComplete?: () => void
}) {
    const { getLayoutId, registerScreen } = useContext(LayoutIdContext)

    useEffect(() => registerScreen(), [registerScreen])
    return (
        <MagicMotionContext.Provider
            value={{
                enabled,
                getLayoutId,
                magicDependency,
                layoutOrder,
                isPresent: areMagicMotionLayersPresent,
            }}
        >
            {children}
        </MagicMotionContext.Provider>
    )
}
