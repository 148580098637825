import * as React from "react"
import { useRef, useCallback, useEffect, useContext } from "react"

/**
 * @internal
 */
export interface GetLayoutId {
    id?: string
    name?: string
    duplicatedFrom?: string[]
}

/**
 * @internal
 */
export const LayoutIdContext = React.createContext<{
    getLayoutId: (args: GetLayoutId) => string | null
    registerScreen: () => void
    top: boolean
}>({
    getLayoutId: args => null,
    registerScreen: () => {},
    top: false,
})

/**
 * @internal
 */
export function LayoutIdProvider({
    children,
    dependency,
}: {
    children: React.ReactNode
    dependency?: number
}): JSX.Element {
    const context = useContext(LayoutIdContext)

    // Since Code Components on the canvas can use Navigation, we need to ensure that only the root LayoutIdContext
    // is generating layoutIds so that the cache is shared across all screens.
    if (context.top) {
        return <>{children}</>
    }

    const dependencyRef = useRef(dependency)

    const cache = useRef({
        // When we provide a layoutId for a node based on it's first duplicatedFrom id, we save it's layoutId mapped to it's actual id.
        // Future screen's nodes will check this cache first, to see if they've previously been assigned a layoutId,
        // or if any of there other duplicatedFrom ids matche a node that was previously assigned a layoutId.
        byId: {},
        byName: {},
        // When we navigate from screens that were duplicated from a future screen, to that future screen,
        // we want to do a reverse lookup on the last duplicatedFrom id, rather than the id.
        // We need to keep them separate so they don't overlap.
        byLastId: {},
        byPossibleId: {},
        byLastName: {},
        byAnimation: {},

        // When we don't have a cached layoutId for all duplicatedFrom ids, we need to increment and save it so that we don't create clashing layoutIds.
        // We also need to reset name counts between screens, so we record those separately.
        count: {
            byId: {},
            byName: {},
        },
    })

    const screen = useRef({
        byId: {},
        byName: {},
        byLastId: {},
        byPossibleId: {},
        byLastName: {},
        byAnimation: {},
    })

    // Keep track of which animationIds have been used on the current screen so that we avoid reassinging them,
    // and instead, use other methods to generate a unique id.
    const usedIds = useRef(new Set()).current

    // This function is quite abstract so I've done my best to annotate why checks are happening.
    // A lot of the complexity comes from handling named and unnamed layers differently.
    const getLayoutId = useCallback(({ id, name, duplicatedFrom }: GetLayoutId) => {
        // Code components that use Frame's should not receive an animation id from our context.
        // However this will be bypassed if end-users add an id to their Frame in code.
        if (!id) return null
        const cacheKey = name ? "byName" : "byId"

        // If we've previously recorded an animation id for this node, reuse it and return early.
        const previousId = cache.current[cacheKey][id]
        if (previousId) {
            usedIds.add(previousId)
            return previousId
        }

        // If the node is an original node (hasn't been duplicated from another node),
        // we use it's name or id, unless it's name or id was already used on this screen,
        // or used by a node that wasn't last duplicated from this node on the previous screen
        // (suggesting another node on this screen will need to use this id in a future call).
        const nodeIdentifier = name || id
        if (
            !duplicatedFrom &&
            !usedIds.has(nodeIdentifier) &&
            (!cache.current.byAnimation[nodeIdentifier] || cache.current.byAnimation[nodeIdentifier] === nodeIdentifier)
        ) {
            if (cache.current.count[cacheKey][nodeIdentifier] === undefined) {
                cache.current.count[cacheKey][nodeIdentifier] = 0
                cache.current.byAnimation[nodeIdentifier] = nodeIdentifier
                screen.current[cacheKey][id] = nodeIdentifier
            }

            usedIds.add(nodeIdentifier)
            return nodeIdentifier
        }

        // If a node is duplicated, check if an animation id was assigned to it on the last screen.
        // Use that animation id if it's not already been used on this screen.
        // This ensures that nodes duplicated from a specific layer on one screen,
        // preserve their connection even if they are in a different hierarchical order on the current screen.
        // This is not relevant for design components since their layers are always in the same order.
        // We also check for matches against `byLastId`, but only use them after we explicitly check `id`.
        let possibleMatch: [string, string] | undefined = undefined
        if (duplicatedFrom?.length) {
            for (let index = duplicatedFrom.length - 1; index >= 0; index--) {
                const duplicatedId = duplicatedFrom[index]
                const match = cache.current[cacheKey][duplicatedId]
                const byLastIdMatch = cache.current.byLastId[duplicatedId]

                // In the event that no match is found for the duplicatedFrom id in the `byId` or `byName` cache,
                // it's possible we will need to loop through the duplicatedFrom ids again, to check if there is a match against the `byLastId` cache.
                // Rather than performing that loop again, we can save the first successful match here, and use it when it's the correct option later.
                // This is safe because we will only use this match if there is no match against `byId` or `byName`,
                // meaning we will always have looped through all of the duplicatedFrom ids.
                if (byLastIdMatch && !possibleMatch) {
                    const matchedAnimation = cache.current.byAnimation[byLastIdMatch]
                    const shouldUseNamedLastIdMatch = !matchedAnimation || matchedAnimation === name

                    if (byLastIdMatch && !usedIds.has(byLastIdMatch) && (name ? shouldUseNamedLastIdMatch : true)) {
                        possibleMatch = [byLastIdMatch, duplicatedId]
                    }
                }

                // If the match from the previous screen is a name match, ensure it is was assigned to the exact same name.
                const previousAnimation = cache.current.byAnimation[match]
                const shouldUseNamedMatch = !previousAnimation || previousAnimation === name

                if (match && !usedIds.has(match) && (name ? shouldUseNamedMatch : true)) {
                    screen.current[cacheKey][id] = match
                    screen.current.byLastId[duplicatedId] = match

                    usedIds.add(match)
                    return match
                }
            }
        }

        // In cases where we're starting on a screen that uses frames duplicated from a future screen,
        // when we arrive on the future screen, we need to make sure we preserve that connection.
        // This handles direct relationships. For example starting on a frame that was duplicated directly from the previous frame,
        // and transitioning to that previous frame.
        const last = cache.current.byLastId[id]
        if (last && !usedIds.has(last)) {
            usedIds.add(last)
            screen.current.byId[id] = last
            return last
        }

        // If we set a possible match by checking duplicatedFrom ids against `byLastId`,
        // and we weren't able to find a match against the `byId` or `byName`,
        // or by directly looking up the node's id against `byLastId`, use a possible match if it was set.
        if (possibleMatch) {
            const [match, duplicatedId] = possibleMatch

            screen.current[cacheKey][id] = match
            screen.current.byLastId[duplicatedId] = match

            usedIds.add(match)
            return match
        }

        // In cases where we're starting on a screen that uses frames duplicated from a future screen,
        // when we arrive on the future screen, we need to make sure we preserve that connection.
        // This handles indirect relationships. For example starting on the last frame duplicated many times from an initial frame,
        // and transitioning directly to that initial frame.
        const possible = cache.current.byPossibleId[id]
        if (possible && !usedIds.has(possible)) {
            usedIds.add(possible)
            screen.current.byId[id] = possible
            return possible
        }

        const rootDuplicatedId = duplicatedFrom?.[0]

        // If a node hasn't been assigned an animation id on a previous screen,
        // or if that animation id has already been used,
        // or if this is the first screen, generate a unique animation id
        // by incrementing a counter for that name or duplicatedId.
        const identifier = name || rootDuplicatedId || id
        const value = cache.current.count[cacheKey][identifier] + 1 || 0

        // We expect 0 to be falsy here so that generated ids match with original ids.
        const animationId = value ? `${identifier}-${value}` : identifier

        cache.current.count[cacheKey][identifier] = value
        screen.current[cacheKey][id] = animationId

        if (duplicatedFrom?.length) {
            // TODO: Should name use it's own map?
            if (!name) {
                screen.current.byLastId[duplicatedFrom[duplicatedFrom.length - 1]] = animationId

                if (duplicatedFrom.length > 1) {
                    // Skipping the most recent duplicatedFrom, and only setting it if there isn't already one set.
                    // This isn't a perfect heuristic since it allows layout hierarchy to influence matches,
                    // since we have to assign on a first-come-first-serve basis.
                    for (let index = 0; index < duplicatedFrom.length - 1; index++) {
                        const possibleId = duplicatedFrom[index]
                        if (!screen.current.byPossibleId[possibleId]) {
                            screen.current.byPossibleId[possibleId] = animationId
                        }
                    }
                }
            }
        }
        screen.current.byAnimation[animationId] = nodeIdentifier

        usedIds.add(identifier)

        return animationId
    }, [])

    const registerScreen = useCallback(() => {
        cache.current = {
            byId: {
                ...cache.current.byId,
                ...screen.current.byId,
            },
            byLastId: {
                ...cache.current.byLastId,
                ...screen.current.byLastId,
            },
            byPossibleId: {
                ...cache.current.byPossibleId,
                ...screen.current.byPossibleId,
            },
            byName: {
                ...cache.current.byName,
                ...screen.current.byName,
            },
            byLastName: { ...cache.current.byLastName, ...screen.current.byLastName },
            byAnimation: { ...cache.current.byAnimation, ...screen.current.byAnimation },

            // Unlike the count.byId, we need to reset the count.byName because named layers
            // might not have duplicatedFrom ids (e.g. imported from Figma).
            // When we can use duplicatedFrom ids to check if an id was assigned on a previous screen,
            // we don't increment the count, which means that the count only increments for new items,
            // and only increments on a new screen if the node is new.
            // Since named layers need to always match in some way between screens, we reset the count so that
            // the second named layer on a second screen is always name-1 if it doesn't have any duplicatedFrom ids.
            count: {
                ...cache.current.count,
                byName: {},
            },
        }

        screen.current = {
            byId: {},
            byName: {},
            byLastId: {},
            byPossibleId: {},
            byLastName: {},
            byAnimation: {},
        }

        usedIds.clear()
    }, [])

    // When a user selects a new screen on the canvas, and Navigation switches the current screen to that screen, no transition occurs,
    // the component is just replaced. This means that the exiting screen will not call registerScreen.
    // By running an effect based on a dependency incremented by Navigation when this event occurs,
    // We can safely clear the maps that we use to generate matching IDs and prevent incorrect ID generation.
    useEffect(() => {
        if (dependency && dependencyRef.current !== dependency) registerScreen()
    }, [dependency, registerScreen])

    return (
        <LayoutIdContext.Provider
            value={{
                getLayoutId,
                registerScreen,
                top: true,
            }}
        >
            {children}
        </LayoutIdContext.Provider>
    )
}
