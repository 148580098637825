import * as React from "react"
import { Transition, AnimateSharedLayout, AnimatePresence } from "framer-motion"
import { FrameWithMotion, FrameProps } from "../render/presentation/Frame"
import {
    TransitionDefaults,
    NavigationTransition,
    pushTransition,
    overlayTransition,
    flipTransition,
    FadeTransitionOptions,
    PushTransitionOptions,
    ModalTransitionOptions,
    OverlayTransitionOptions,
    FlipTransitionOptions,
    NavigationTransitionAnimation,
    NavigationTransitionBackdropColor,
} from "./NavigationTransitions"
import { NavigationContainer, allAnimatableProperties } from "./NavigationContainer"
import { isReactChild, isReactElement } from "../utils/type-guards"
import { injectComponentCSSRules } from "../render/utils/injectComponentCSSRules"
import { navigatorMock } from "./NavigatorMock"
import { LayoutIdProvider } from "./LayoutIdContext"

/**
 * The navigator allows control over the built-in navigation component in Framer.
 * @beta
 */
export interface NavigationInterface {
    /**
     * Go back to the previous screen. If a stack of overlays is presented, all overlays are dismissed.
     * @beta
     * */
    goBack: () => void
    /**
     * Show new screen instantly.
     * @param component - The incoming component
     * @beta
     */
    instant: (component: React.ReactNode) => void
    /**
     * Fade in new screen.
     * @param component - The incoming component
     * @param options - {@link FadeTransitionOptions}
     * @beta
     */
    fade: (component: React.ReactNode, options?: FadeTransitionOptions) => void
    /**
     * Push new screen. Defaults from right to left, the direction can be changed using the {@link NavigationTransitionOptions}.
     * @param component - The incoming component
     * @param options - {@link PushTransitionOptions}
     * @beta
     */
    push: (component: React.ReactNode, options?: PushTransitionOptions) => void
    /**
     * Present modal overlay in the center.
     * @param component - The incoming component
     * @param options - {@link ModalTransitionOptions}
     * @beta
     */
    modal: (component: React.ReactNode, options?: ModalTransitionOptions) => void
    /**
     * Present overlay from one of four edges. The direction can be changed using the {@link NavigationTransitionOptions}.
     * @param component - The incoming component
     * @param options - {@link OverlayTransitionOptions}
     * @beta
     */
    overlay: (component: React.ReactNode, options?: OverlayTransitionOptions) => void
    /**
     * Flip incoming and outgoing screen in 3D. The flip direction can be changed using the {@link NavigationTransitionOptions}.
     * @param component - The incoming component
     * @param options - {@link FlipTransitionOptions}
     * @beta
     */
    flip: (component: React.ReactNode, options?: FlipTransitionOptions) => void
    /**
     * Present a screen using a custom {@link NavigationTransition}.
     * @param component - The incoming component
     * @param transition - {@link NavigationTransition}
     * @beta
     */
    customTransition: (component: React.ReactNode, transition: NavigationTransition) => void
    /**
     * Animate layers with matching magicIds between screens. Layers are assigned matching IDs if they share a name, or were copied from one another.
     * The transition can be changed using a custom {@link NavigationTransition}.
     * @param component - The incoming component
     * @param transition - {@link NavigationTransition}
     * @beta
     */
    magicMotion: (component: React.ReactNode, transition: NavigationTransition) => void
}

/**
 * @internal
 */
export const NavigationContext = React.createContext<NavigationInterface>(navigatorMock)

/**
 * Provides {@link NavigationInterface} that can be used to start transitions in Framer.
 * @beta
 */
export const NavigationConsumer = NavigationContext.Consumer

/**
 * @internal
 */
export interface NavigationProps {
    /** @deprecated - still used by the old library */
    width?: number
    /** @deprecated - still used by the old library */
    height?: number
    style?: React.CSSProperties

    /** @private used to stub out requestAnimationFrame to instantly return in unit tests. */
    _requestAnimationFrame?: any
}

interface NavigationState {
    current: number
    previous: number
    currentOverlay: number
    previousOverlay: number
    animationIdDependency?: number
    magicMotionDependency?: string | number
}

interface StackState {
    current: number
    previous: number
    history: HistoryItem[]
}

type ComponentKey = string

interface HistoryItem {
    key: ComponentKey
    transition: NavigationTransition
    component?: React.ReactNode
}

/**
 * @internal
 */
export class Navigation extends React.Component<NavigationProps, NavigationState> implements NavigationInterface {
    private previousTransition: NavigationTransition | null = null
    private lastEventTimeStamp: number | null = null
    private history: HistoryItem[] = []
    private components: Record<ComponentKey, React.ReactNode> = {}
    private componentIndex: Record<ComponentKey, number> = {}
    private transitionForComponent: Record<ComponentKey, Record<string, any>> = {}
    private overlayStack: HistoryItem[] = []
    private overlayItemId = 0
    private historyItemId = 0

    state: NavigationState = {
        current: -1,
        previous: -1,
        currentOverlay: -1,
        previousOverlay: -1,
        animationIdDependency: 0,
        magicMotionDependency: "stack-0",
    }

    componentDidMount() {
        if (this.history.length === 0) {
            this.transition(this.props.children, TransitionDefaults.Instant)
        }
        injectComponentCSSRules()
    }

    UNSAFE_componentWillReceiveProps(props: NavigationProps) {
        const component: React.ReactNode = props["children"]
        if (!React.isValidElement(component) || typeof component === "string") return

        const key = component.key?.toString()

        if (!key) return

        if (this.history.length === 0) {
            this.transition(component, TransitionDefaults.Instant)
        } else {
            this.components[key] = component
            this.setState({
                animationIdDependency: (this.state.animationIdDependency || 0) + 1,
            })
        }
    }

    private getStackState(options: { overCurrentContext: boolean }): StackState {
        const { current, previous, currentOverlay, previousOverlay } = this.state
        if (options.overCurrentContext) {
            return {
                current: currentOverlay,
                previous: previousOverlay,
                history: this.overlayStack,
            }
        }

        return {
            current,
            previous,
            history: this.history,
        }
    }

    private newOverlayItem(component: React.ReactNode, transition: NavigationTransition): HistoryItem {
        this.overlayItemId++
        return {
            key: `stack-${this.overlayItemId}`,
            component,
            transition,
        }
    }

    // To prevent bubbling events from triggering multiple transitions,
    // we ensure that the current event has a different timestamp then the event that triggered the last transition.
    // We use Window.event to ensure that even transitions invoked by code components - and may not pass a reference to the event - are caught.
    // This works better than measuring the time of transition calls with performance.now()
    // because the time between calls can get longer and longer as more screens are added to the stack,
    // preventing a deterministic time between transitions to be used to determine if they were triggered at the same time or not.
    private isSameEventTransition() {
        // If for some reason window.event is undefined, don't block transitions.
        if (!window.event) return false
        return this.lastEventTimeStamp === window.event.timeStamp
    }

    private transition(
        component: React.ReactNode,
        transitionTraits: NavigationTransition,
        transitionOptions?: NavigationTransitionAnimation & NavigationTransitionBackdropColor
    ) {
        if (this.isSameEventTransition()) return
        this.lastEventTimeStamp = window.event?.timeStamp || null

        if (!component) return

        const transition = { ...transitionTraits, ...transitionOptions }
        const overCurrentContext = !!transition.overCurrentContext

        if (overCurrentContext) {
            // Don't push the same screen twice.
            const currentOverlay = this.overlayStack[this.state.currentOverlay]
            if (currentOverlay && currentOverlay.component === component) return

            this.updateOverlayStack(component, transition)
            return
        }
        if (!React.isValidElement(component) || typeof component === "string") return

        // If for some reason Navigation is being used in code, and a component instance isn't supplied,
        // generate a unique key to ensure the screen is added.
        this.historyItemId++
        const key = component?.key?.toString() || `stack-${this.historyItemId}`

        if (!this.components[key]) this.components[key] = component

        // Restart history from current, erasing navigations that we have "gone back" from.
        this.history = this.history.slice(0, this.state.current + 1)

        const lastHistoryItem = this.history[this.history.length - 1]

        const isCurrentScreen = lastHistoryItem && lastHistoryItem.key === key

        // In the rare case where a navigation from an overlay, to the screen under the overlay is triggered,
        // just dismiss the overlay.
        this.overlayStack = []

        if (isCurrentScreen && this.state.currentOverlay > -1) {
            this.setState({
                currentOverlay: -1,
                previousOverlay: this.state.currentOverlay,
            })

            return
        }

        // Don't push the same screen twice.
        if (isCurrentScreen) return

        this.history.push({
            key,
            transition,
        })

        this.componentIndex[key] = this.history.length - 1

        const current = this.state.current + 1
        const previous = this.state.current

        this.updateTransitions({ current, previous })

        this.setState({
            current,
            previous,
            currentOverlay: -1,
            previousOverlay: this.state.currentOverlay,
            magicMotionDependency: transitionTraits.withMagicMotion
                ? performance.now().toString()
                : this.state.magicMotionDependency,
        })
    }

    private updateOverlayStack(component: React.ReactNode, transition: NavigationTransition) {
        this.overlayStack = [...this.overlayStack, this.newOverlayItem(component, transition)]
        this.setState({
            currentOverlay: Math.max(0, Math.min(this.state.currentOverlay + 1, this.overlayStack.length - 1)),
            previousOverlay: this.state.currentOverlay,
        })
    }

    private updateTransitions({ current, previous }: { current: number; previous: number }) {
        // Here, we set the transition for a component to a hash map if it becomes the current or previous screen.
        // We don't set to the hash map if it is not the current or previous screen, to allow running animations to play out.
        Object.keys(this.componentIndex).forEach(key => {
            const index = this.componentIndex[key]
            const transition = transitionForScreen(index, { current, previous, history: this.history })
            if (transition) this.transitionForComponent[key] = transition
        })
    }

    goBack = () => {
        if (this.isSameEventTransition()) return
        this.lastEventTimeStamp = window.event?.timeStamp || null

        if (this.state.currentOverlay !== -1) {
            this.overlayStack = []
            this.setState({ currentOverlay: -1, previousOverlay: this.state.currentOverlay })
            return
        }

        const history = [...this.history.slice(0, this.state.current + 1)]

        // Don't remove the last component.
        if (history.length === 1) return

        const last = history.pop()

        if (!last) return

        const nextKey = history[history.length - 1].key
        this.componentIndex[nextKey] = history.length - 1

        if (last.transition.withMagicMotion) {
            this.previousTransition = last.transition || null
        }

        const shouldRemoveComponent = history.findIndex(item => item.key === last.key) === -1
        if (shouldRemoveComponent) {
            // Remove the component from the cache, triggering it's removal from the DOM.
            delete this.components[last.key]
        }

        const current = this.state.current - 1
        const previous = this.state.current

        this.updateTransitions({ current, previous })

        this.setState({
            current,
            previous,
            magicMotionDependency: last.transition.withMagicMotion
                ? performance.now().toString()
                : this.state.magicMotionDependency,
        })
    }

    instant(component: React.ReactNode) {
        this.transition(component, TransitionDefaults.Instant)
    }

    fade(component: React.ReactNode, options?: FadeTransitionOptions) {
        this.transition(component, TransitionDefaults.Fade, options)
    }

    push(component: React.ReactNode, options?: PushTransitionOptions) {
        this.transition(component, pushTransition(options), options)
    }

    modal(component: React.ReactNode, options?: ModalTransitionOptions) {
        this.transition(component, TransitionDefaults.Modal, options)
    }

    overlay(component: React.ReactNode, options?: OverlayTransitionOptions) {
        this.transition(component, overlayTransition(options), options)
    }

    flip(component: React.ReactNode, options?: FlipTransitionOptions) {
        this.transition(component, flipTransition(options), options)
    }

    magicMotion(component: React.ReactNode, options?: NavigationTransitionAnimation) {
        this.transition(component, TransitionDefaults.MagicMotion, options)
    }

    customTransition(component: React.ReactNode, transition: NavigationTransition) {
        this.transition(component, transition)
    }

    render() {
        const stackState = this.getStackState({ overCurrentContext: false })
        const overlayStackState = this.getStackState({ overCurrentContext: true })
        const activeOverlay = activeOverlayItem(overlayStackState)

        return (
            <FrameWithMotion
                top={0}
                left={0}
                width={"100%"}
                height={"100%"}
                position={"relative"}
                style={{ overflow: "hidden", backgroundColor: "unset", ...this.props.style }}
            >
                <NavigationContext.Provider value={this}>
                    <NavigationContainer
                        isLayeredContainer={true}
                        position={undefined}
                        initialProps={{}}
                        instant={false}
                        transitionProps={transitionPropsForStackWrapper(activeOverlay)}
                        animation={animationForStackWrapper(activeOverlay)}
                        backfaceVisible={backfaceVisibleForStackWrapper(activeOverlay)}
                        visible={true}
                        backdropColor={undefined}
                        onTapBackdrop={undefined}
                        index={0}
                    >
                        <LayoutIdProvider dependency={this.state.animationIdDependency}>
                            <AnimateSharedLayout
                                key={this.state.animationIdDependency} // Workaround to account for children being remounted, instead of updated, when the preview updates.
                                type="crossfade"
                                supportRotate
                                transition={transitionForMagicMotion(stackState, this.previousTransition)}
                                dependency={this.state.magicMotionDependency}
                            >
                                <AnimatePresence>
                                    {Object.keys(this.components).map(key => {
                                        const component = this.components[key]
                                        const index = this.componentIndex[key]
                                        const historyItem = this.history[index]
                                        const transitionProps = this.transitionForComponent[key]

                                        return (
                                            <NavigationContainer
                                                key={key}
                                                index={index}
                                                isCurrent={index === this.state.current}
                                                isPrevious={index === this.state.previous}
                                                visible={index === this.state.current || index === this.state.previous}
                                                position={historyItem?.transition?.position}
                                                instant={isInstantContainerTransition(index, stackState)}
                                                transitionProps={transitionProps}
                                                animation={animationPropsForContainer(index, stackState)}
                                                backfaceVisible={getBackfaceVisibleForScreen(index, stackState)}
                                                exitProps={historyItem?.transition?.enter}
                                                exitAnimation={historyItem?.transition?.animation}
                                                exitBackfaceVisible={historyItem?.transition?.backfaceVisible}
                                                withMagicMotion={historyItem?.transition?.withMagicMotion}
                                                magicDependency={index === this.state.current}
                                                areMagicMotionLayersPresent={
                                                    index > this.state.current &&
                                                    this.state.current < this.state.previous
                                                        ? false
                                                        : undefined
                                                }
                                            >
                                                {containerContent({ component, transition: historyItem?.transition })}
                                            </NavigationContainer>
                                        )
                                    })}
                                </AnimatePresence>
                            </AnimateSharedLayout>
                        </LayoutIdProvider>
                    </NavigationContainer>
                    <AnimatePresence>
                        {this.overlayStack.map((item, stackIndex) => {
                            return (
                                <NavigationContainer
                                    isLayeredContainer={true}
                                    key={item.key}
                                    position={item.transition.position}
                                    initialProps={initialPropsForOverlay(stackIndex, overlayStackState)}
                                    transitionProps={transitionPropsForOverlay(stackIndex, overlayStackState)}
                                    instant={isInstantContainerTransition(stackIndex, overlayStackState, true)}
                                    animation={animationPropsForContainer(stackIndex, overlayStackState)}
                                    exitProps={item.transition.enter}
                                    visible={containerIsVisible(stackIndex, overlayStackState)}
                                    backdropColor={backdropColorForTransition(item.transition)}
                                    backfaceVisible={getBackfaceVisibleForOverlay(stackIndex, overlayStackState)}
                                    onTapBackdrop={backdropTapAction(item.transition, this.goBack)}
                                    index={this.state.current + 1 + stackIndex}
                                >
                                    {containerContent({ component: item.component, transition: item.transition })}
                                </NavigationContainer>
                            )
                        })}
                    </AnimatePresence>
                </NavigationContext.Provider>
            </FrameWithMotion>
        )
    }
}

const animationDefault: Transition = {
    stiffness: 500,
    damping: 50,
    restDelta: 1,
    type: "spring",
}

type ActiveOverlay = { currentOverlayItem: HistoryItem | undefined; previousOverlayItem: HistoryItem | undefined }

function activeOverlayItem(overlayStack: StackState): ActiveOverlay {
    let currentOverlayItem: HistoryItem | undefined
    let previousOverlayItem: HistoryItem | undefined
    if (overlayStack.current !== -1) {
        currentOverlayItem = overlayStack.history[overlayStack.current]
    } else {
        previousOverlayItem = overlayStack.history[overlayStack.previous]
    }
    return { currentOverlayItem, previousOverlayItem }
}

function transitionPropsForStackWrapper({ currentOverlayItem }: ActiveOverlay) {
    return currentOverlayItem && currentOverlayItem.transition.exit
}

function animationForStackWrapper({ currentOverlayItem, previousOverlayItem }: ActiveOverlay): Transition {
    if (currentOverlayItem && currentOverlayItem.transition.animation) {
        return currentOverlayItem.transition.animation
    }
    if (previousOverlayItem && previousOverlayItem.transition.animation) {
        return previousOverlayItem.transition.animation
    }
    return animationDefault
}

function backfaceVisibleForStackWrapper({ currentOverlayItem, previousOverlayItem }: ActiveOverlay) {
    if (currentOverlayItem) return currentOverlayItem.transition.backfaceVisible
    return previousOverlayItem && previousOverlayItem.transition.backfaceVisible
}

function backdropColorForTransition(transition: NavigationTransition): string | undefined {
    if (transition.backdropColor) return transition.backdropColor
    if (transition.overCurrentContext) return "rgba(4,4,15,.4)" // iOS dim color
    return undefined
}

function getBackfaceVisibleForOverlay(containerIndex: number, stackState: StackState): boolean | undefined {
    const { current, history } = stackState
    if (containerIndex === current) {
        // current
        const navigationItem = history[containerIndex]
        if (navigationItem && navigationItem.transition) {
            return navigationItem.transition.backfaceVisible
        }
        return true
    } else if (containerIndex < current) {
        // old
        const navigationItem = history[containerIndex + 1]
        if (navigationItem && navigationItem.transition) {
            return navigationItem.transition.backfaceVisible
        }
        return true
    } else {
        // future
        const navigationItem = history[containerIndex]
        if (navigationItem && navigationItem.transition) {
            return navigationItem.transition.backfaceVisible
        }
        return true
    }
}

function initialPropsForOverlay(containerIndex: number, stackState: StackState): Partial<FrameProps> | undefined {
    const navigationItem = stackState.history[containerIndex]

    if (navigationItem) return navigationItem.transition.enter
}

function transitionForScreen(screenIndex: number, stackState: StackState) {
    const { current, previous, history } = stackState

    // If a screen has already exited, or entered and is underneath the stack,
    // don't update it's animation, allowing any current animations to play out.
    if (screenIndex !== current && screenIndex !== previous) return undefined

    // Entering going forward
    if (screenIndex === current && current > previous) {
        const item = history[screenIndex]
        return sequence("enter", item.transition.enter, item.transition.animation)
    }

    // Exiting going forward
    if (screenIndex === previous && current > previous) {
        const item = history[screenIndex + 1]
        return sequence("exit", item.transition.exit, item.transition.animation)
    }

    // Entering going backwards
    if (screenIndex === current && current < previous) {
        const item = history[screenIndex + 1]
        return sequence("enter", item.transition.exit, item.transition.animation)
    }

    // Exiting going backwards
    if (screenIndex === previous && current < previous) {
        const item = history[screenIndex]
        return sequence("exit", item.transition.enter, item.transition.animation)
    }
}

function getBackfaceVisibleForScreen(screenIndex: number, stackState: StackState): boolean | undefined {
    const { current, previous, history } = stackState

    // Entering going backwards || exiting going forward
    if ((screenIndex === previous && current > previous) || (screenIndex === current && current < previous)) {
        return history[screenIndex + 1]?.transition?.backfaceVisible
    }

    // Entering going forward, exiting going backwards, or all other screens.
    return history[screenIndex]?.transition?.backfaceVisible
}

function transitionPropsForOverlay(overlayIndex: number, stackState: StackState): Partial<FrameProps> | undefined {
    const { current, history } = stackState

    if (overlayIndex === current) {
        // current
        return
    } else if (overlayIndex < current) {
        // old
        const navigationItem = history[overlayIndex + 1]
        if (navigationItem && navigationItem.transition) {
            return navigationItem.transition.exit
        }
    } else {
        // future
        const navigationItem = history[overlayIndex]
        if (navigationItem && navigationItem.transition) {
            return navigationItem.transition.enter
        }
    }
}

function animationPropsForContainer(containerIndex: number, stackState: StackState): Transition {
    const { current, previous, history } = stackState
    const containerCurrent = previous > current ? previous : current
    if (containerIndex < containerCurrent) {
        // old
        const navigationItem = history[containerIndex + 1]
        if (navigationItem && navigationItem.transition.animation) {
            return navigationItem.transition.animation
        }
    } else if (containerIndex !== containerCurrent) {
        // future
        const navigationItem = history[containerIndex]
        if (navigationItem && navigationItem.transition.animation) {
            return navigationItem.transition.animation
        }
    } else {
        // current
        const navigationItem = history[containerIndex]
        if (navigationItem.transition.animation) {
            return navigationItem.transition.animation
        }
    }

    return animationDefault
}

function isInstantContainerTransition(
    containerIndex: number,
    stackState: StackState,
    overCurrentContext?: boolean
): boolean {
    const { current, previous, history } = stackState
    if (overCurrentContext && history.length > 1) return true
    if (containerIndex !== previous && containerIndex !== current) return true
    if (current === previous) return true
    return false
}

function containerIsVisible(containerIndex: number, stackState: StackState) {
    const { current, previous } = stackState
    if (containerIndex > current && containerIndex > previous) return false
    if (containerIndex === current) return true

    return false
}

function containerContent(item: { component: React.ReactNode; transition: NavigationTransition }) {
    return React.Children.map(item.component, (child: React.ReactElement<{ [key: string]: any } | undefined>) => {
        if (!isReactChild(child) || !isReactElement(child) || !child.props) {
            return child
        }

        const props: Partial<{ width: number | string; height: number | string }> = {}

        const position = item?.transition?.position
        const shouldStretchWidth = !position || (position.left !== undefined && position.right !== undefined)
        const shouldStretchHeight = !position || (position.top !== undefined && position.bottom !== undefined)

        const canStretchWidth = "width" in child.props
        const canStretchHeight = "height" in child.props
        if (shouldStretchWidth && canStretchWidth) {
            props.width = "100%"
        }
        if (shouldStretchHeight && canStretchHeight) {
            props.height = "100%"
        }

        return React.cloneElement(child, props)
    })
}

function backdropTapAction(transition: NavigationTransition, goBackAction: () => void) {
    if (transition.goBackOnTapOutside !== false) return goBackAction
}

function transitionForMagicMotion(stackState: StackState, previousTransition: NavigationTransition | null) {
    const { current, previous, history } = stackState
    const animation = current > previous ? history[current]?.transition?.animation : previousTransition?.animation

    return animation || animationDefault
}

const allAnimatableKeys = Object.keys(allAnimatableProperties)
function sequence(direction: "enter" | "exit", transition?: any, animation?: Transition) {
    const value = {}
    const from = {}

    allAnimatableKeys.forEach(property => {
        value[property] = allAnimatableProperties[property]
        from[property] = {
            ...animation,
            from: allAnimatableProperties[property],
        }
    })

    if (transition) {
        Object.keys(transition as {}).forEach(property => {
            if (transition[property] === undefined) return

            const transitionTo = transition[property]
            const transitionFrom =
                typeof transition[property] === "string"
                    ? `${allAnimatableProperties[property]}%`
                    : allAnimatableProperties[property]

            value[property] = direction === "enter" ? transitionFrom : transitionTo
            from[property] = {
                ...animation,
                from: direction === "enter" ? transitionTo : transitionFrom,
            }
        })
    }

    // Always return at least an identity animation.
    return {
        ...value,
        transition: {
            ...from,
        },
    }
}
