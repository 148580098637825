import * as React from "react"
import { LayerProps, IdentityProps } from "./Layer"
import { Rect } from "../types/Rect"
import { Size } from "../types/Size"
import {
    ComponentIdentifier,
    SandboxReactComponentDefinition,
    isReactDefinition,
    ReactComponentDefinition,
} from "../componentLoader"
import { ComponentContainerLoader } from "./ComponentContainerLoader"
import { isEqual } from "../utils/isEqual"
import { isShallowEqualArray } from "../utils/isShallowEqualArray"
import { serverURL } from "../assetResolver"
import { safeWindow } from "../../utils/safeWindow"
import { runtime } from "../../utils/runtimeInjection"

// Subset of CanvasNodeCache
/** @internal */
export interface PresentationTreeCache {
    props: any | null
    canvasZoom: number
    willChangeTransform: boolean
    reactElement: React.ReactNode | null
    codeComponentPresentation: CodeComponentPresentation | null
    placeholders: { index: number; sizes: Size[] } | null
}

/** @internal */
export interface PresentationTree {
    rect(parentSize: Size | null): Rect
    getProps(): IdentityProps
    children?: PresentationTree[]
    cache: Partial<PresentationTreeCache>
}

/**
 * @internal
 */
export function addServerUrlToResourceProps(props: { [key: string]: any }): { [key: string]: any } {
    const serverResources = props.__serverResources
    if (serverResources && Array.isArray(serverResources)) {
        const previousProps = props
        props = { ...props }
        for (const resourceName of serverResources) {
            if (Array.isArray(resourceName)) {
                const [name, at] = resourceName
                let array = props[name]
                if (!array) continue

                // make sure to copy-on-write
                if (previousProps[name] === array) {
                    array = array.slice()
                    props[name] = array
                }
                array[at] = serverURL(array[at])
            } else if (typeof resourceName === "string") {
                const value = props[resourceName]
                if (!value) continue

                props[resourceName] = serverURL(value)
            }
        }
    }
    return props
}

// For performance, we cache the React element on the node.cache, so we only have to create elements
// for nodes that actually changed. Which is a 10x speedup.
function reactConverter<P extends LayerProps>(componentForNode: (node: PresentationTree) => React.ComponentType<P>) {
    return function(node: PresentationTree, children?: React.ReactNode[]) {
        const cache = node.cache

        if (process.env.NODE_ENV !== "production" && safeWindow["perf"]) safeWindow["perf"].nodeCreateElement()

        let props = cache.props
        if (!props) {
            if (process.env.NODE_ENV !== "production" && safeWindow["perf"]) safeWindow["perf"].nodeGetProps()
            props = cache.props = node.getProps()
        }

        const canvasZoom = cache.canvasZoom
        const willChangeTransform = cache.willChangeTransform
        if (canvasZoom || willChangeTransform) {
            props = { ...props, canvasZoom, willChangeTransform }
        }

        props = addServerUrlToResourceProps(props)

        if (!(node instanceof CodeComponentPresentation)) {
            const component = componentForNode(node)
            return (cache.reactElement = React.createElement(component, props, children))
        } else {
            const component = runtime.componentLoader.componentForIdentifier(node.componentIdentifier)
            if (!component) {
                // if there is an error, the componentcontainer will take care of it
                const error = runtime.componentLoader.errorForIdentifier(node.componentIdentifier)
                if (error) return null

                // otherwise show a loading object
                return (cache.reactElement = React.createElement(ComponentContainerLoader, {
                    key: "component-container-loader",
                    error,
                }))
            }

            if (!isReactDefinition(component)) return null

            return (cache.reactElement = React.createElement(component.class, props, children))
        }
    }
}

/**
 * @internal
 */
export function renderPresentationTree<P extends LayerProps>(
    node: PresentationTree,
    componentForNode: (node: PresentationTree) => React.ComponentType<P>,
    componentDefinitionProvider: ReactComponentDefinitionProvider
): React.ReactNode {
    return convertPresentationTree(
        node,
        reactConverter(componentForNode),
        componentDefinitionProvider,
        (n: PresentationTree) => n.cache.reactElement
    )
}

/**
 * @internal
 */
export function convertPresentationTree<C, P extends LayerProps>(
    node: PresentationTree,
    converter: (node: PresentationTree, children: C[] | undefined) => C,
    componentDefinitionProvider: ReactComponentDefinitionProvider,
    getCachedNode?: (node: PresentationTree) => C | undefined
): C {
    // if there is a cached node, we use it without looking at any children etc.
    const cachedNode = getCachedNode && getCachedNode(node)
    if (cachedNode) return cachedNode

    // otherwise build children depth first and convert node
    let children: C[] | undefined
    if (isCodeComponentContainerPresentation(node)) {
        children = convertCodeComponentContainer(componentDefinitionProvider, node, converter, getCachedNode)
    } else if (node.children) {
        children = node.children.map(n =>
            convertPresentationTree(n, converter, componentDefinitionProvider, getCachedNode)
        )
    }
    return converter(node, children)
}

/**
 * A trimmed down version of the `componentLoader` interface
 *
 * @internal
 */
interface ReactComponentDefinitionProvider {
    reactComponentForIdentifier(
        identifier: ComponentIdentifier
    ): SandboxReactComponentDefinition | ReactComponentDefinition | null
}

interface CodeComponentContainerPresentation extends PresentationTree {
    id: string
    codeComponentIdentifier: string
    codeComponentPackageVersion: string | null
    codeOverrideIdentifier?: string
    getComponentChildren?: (componentDefinitionProvider: ReactComponentDefinitionProvider) => PresentationTree[]
    getCodeComponentProps?: (componentDefinitionProvider: ReactComponentDefinitionProvider) => object
    getComponentSlotChildren?: (
        componentDefinitionProvider: ReactComponentDefinitionProvider
    ) => { [key: string]: PresentationTree[] }
}

function isCodeComponentContainerPresentation(value: PresentationTree): value is CodeComponentContainerPresentation {
    return !!(value as any).codeComponentIdentifier
}

function convertCodeComponentContainer<C>(
    componentDefinitionProvider: ReactComponentDefinitionProvider,
    node: CodeComponentContainerPresentation,
    converter: (node: PresentationTree, children: C[] | undefined) => C,
    getCachedNode?: (node: PresentationTree) => C | undefined
) {
    const codeComponentChildren = !!node.getComponentChildren
        ? node.getComponentChildren(componentDefinitionProvider)
        : []
    const codeComponentSlots = !!node.getComponentSlotChildren
        ? node.getComponentSlotChildren(componentDefinitionProvider)
        : {}

    let codeComponentPresentation: CodeComponentPresentation

    const props = node.getCodeComponentProps ? node.getCodeComponentProps(componentDefinitionProvider) : undefined

    if (node.cache.codeComponentPresentation) {
        codeComponentPresentation = node.cache.codeComponentPresentation
        if (!isShallowEqualArray(codeComponentPresentation.children, codeComponentChildren)) {
            codeComponentPresentation.cache.reactElement = null
            codeComponentPresentation.children = codeComponentChildren
        }
        if (!isEqual(codeComponentPresentation.props, props)) {
            codeComponentPresentation.cache.reactElement = null
            codeComponentPresentation.cache.props = null
            codeComponentPresentation.props = props
        }
    } else {
        const { id: containerId, codeComponentIdentifier: identifier, codeComponentPackageVersion } = node

        node.cache.codeComponentPresentation = codeComponentPresentation = new CodeComponentPresentation(
            containerId + identifier,
            identifier,
            codeComponentPackageVersion,
            props,
            codeComponentChildren
        )
    }

    codeComponentPresentation.props.placeholders = node.cache.placeholders

    const slotKeys = Object.keys(codeComponentSlots)

    if (slotKeys.length) {
        codeComponentPresentation.props = { ...codeComponentPresentation.props }
        codeComponentPresentation.props.__slotKeys = slotKeys

        for (const slotKey of slotKeys) {
            const slotChildren = codeComponentSlots[slotKey].map(child =>
                convertPresentationTree(child, converter, componentDefinitionProvider, getCachedNode)
            )
            codeComponentPresentation.props[slotKey] = slotChildren
        }
    }

    return [
        converter(
            codeComponentPresentation,
            codeComponentPresentation.children.map(child =>
                convertPresentationTree(child, converter, componentDefinitionProvider, getCachedNode)
            )
        ),
    ]
}

/** @internal */
class CodeComponentPresentation implements PresentationTree {
    cache: Partial<PresentationTreeCache> = {}

    constructor(
        private id: string,
        public componentIdentifier: string,
        public packageVersion: string | null,
        public props: any,
        public children: PresentationTree[],
        public codeOverrideIdentifier?: string
    ) {}

    getProps = () => {
        return {
            ...this.props,
            id: this.id,
            key: this.id,
        }
    }

    rect = (parentSize: Size | null) => {
        // N.B. This is never called.
        return { x: 0, y: 0, width: 0, height: 0 }
    }
}
