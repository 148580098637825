import * as React from "react"
import { MotionPlugins } from "framer-motion"
import { autoValueHandlers } from "./autoValueHandlers"
import { layoutTransition } from "./layoutTransition"

interface Props {
    children: React.ReactNode
}

/** @public */
export function MotionSetup({ children }: Props) {
    return (
        <MotionPlugins autoValues={autoValueHandlers} features={[layoutTransition]}>
            {children}
        </MotionPlugins>
    )
}
