import * as React from "react"
import { motion, MotionStyle, MotionValue } from "framer-motion"
import { MagicMotionProps } from "../../render/utils/useMagicMotionProps"

/** @public */
export type BorderStyle = "solid" | "dashed" | "dotted" | "double"
/** @internal */
export type BorderProperties = {
    borderWidth: number | Partial<{ top: number; bottom: number; left: number; right: number }>
    borderColor: string
    borderStyle: BorderStyle
    border?: string | MotionValue<string>
    magicMotionProps?: MagicMotionProps
}
/** @internal */
export function collectBorderStyleForProps(
    props: Partial<BorderProperties>,
    style: React.CSSProperties,
    collapseEqualBorders: boolean = true
) {
    const { borderWidth, borderStyle, borderColor } = props
    if (!borderWidth) {
        return
    }
    let borderTop: number
    let borderBottom: number
    let borderLeft: number
    let borderRight: number
    if (typeof borderWidth === "number") {
        borderTop = borderBottom = borderLeft = borderRight = borderWidth
    } else {
        borderTop = borderWidth.top || 0
        borderBottom = borderWidth.bottom || 0
        borderLeft = borderWidth.left || 0
        borderRight = borderWidth.right || 0
    }
    if (borderTop === 0 && borderBottom === 0 && borderLeft === 0 && borderRight === 0) {
        return
    }

    // Equal border
    if (collapseEqualBorders && borderTop === borderBottom && borderTop === borderLeft && borderTop === borderRight) {
        style.border = `${borderTop}px ${borderStyle} ${borderColor}`
        return
    }

    style.borderStyle = props.borderStyle
    style.borderColor = props.borderColor
    style.borderTopWidth = `${borderTop}px`
    style.borderBottomWidth = `${borderBottom}px`
    style.borderLeftWidth = `${borderLeft}px`
    style.borderRightWidth = `${borderRight}px`
}

export function Border(props: Partial<BorderProperties>): JSX.Element | null {
    if (!props.borderWidth) {
        return null
    }
    const style: React.CSSProperties = {
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        borderRadius: "inherit",
        pointerEvents: "none",
    }

    if (props.border) {
        ;(style as MotionStyle).border = props.border
        return <motion.div style={style} />
    }

    collectBorderStyleForProps(props, style, false)

    return (
        <motion.div
            style={style}
            {...props.magicMotionProps}
            layoutId={props.magicMotionProps ? `${props.magicMotionProps.layoutId}"-border` : undefined}
        />
    )
}
