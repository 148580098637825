import { Typeface, TypefaceSourceNames, ReadonlyTypeface, TypefaceLocator, ReadonlyFont } from "./types"

export const customFontSelectorPrefix = "CUSTOM;"
/** @internal */
export class CustomFontSource {
    name: TypefaceSourceNames = TypefaceSourceNames.Custom
    typefaces: Typeface[] = []
    byFamily = new Map<string, Typeface>()

    createTypeface(family: string): Typeface {
        throw new Error()
    }

    private parseSelector(selector: string): TypefaceLocator | null {
        if (!selector.startsWith(customFontSelectorPrefix)) return null
        const tokens = selector.split(customFontSelectorPrefix)
        const locator: TypefaceLocator = { source: "custom", family: tokens[1] }
        return locator
    }

    getFontBySelector(selector: string): ReadonlyFont | null {
        const locator = this.parseSelector(selector)
        if (!locator) return null
        return this.getTypefaceByFamily(locator.family).fonts[0]
    }
    getTypefaceByFamily(family: string): ReadonlyTypeface {
        const typeface: Typeface = {
            source: "custom",
            family,
            fonts: [],
        }
        typeface.fonts.push({ selector: `${customFontSelectorPrefix}${family}`, variant: "", typeface })
        return typeface
    }
}
