import { useRef, useContext } from "react"
import { MagicMotionContext } from "../../components/MagicMotionContext"
import { PresenceContext } from "framer-motion"

interface UseMagicMotionProps {
    name?: string
    id?: string
    duplicatedFrom?: string[]
    _canMagicMotion?: boolean
}
/**
 * @internal
 */
export function useMagicMotionProps({
    name,
    id,
    duplicatedFrom,
    _canMagicMotion = false,
}: UseMagicMotionProps): MagicMotionProps | false {
    const layoutIdRef = useRef<string | null>(null)
    const { enabled, getLayoutId, magicDependency, layoutOrder, isPresent } = useContext(MagicMotionContext)
    const presence = useContext(PresenceContext)

    const layoutId = _canMagicMotion ? layoutIdRef.current || getLayoutId({ id, name, duplicatedFrom }) : null
    if (!layoutIdRef.current) layoutIdRef.current = layoutId

    if (id && enabled && layoutId) {
        const props: MagicMotionProps = {
            key: id,
            layoutId,
            magicDependency,
            layoutOrder,
        }

        if (isPresent !== undefined && isPresent === false) {
            props["data-is-present"] = false
            props.isPresent = false
        } else if (presence !== null && !presence.isPresent) {
            props["data-is-present"] = false
        }

        return props
    }

    return false
}

/**
 * @internal
 */
export type MagicMotionProps =
    | {
          key: string
          layoutId: string
          layoutOrder: number
          isPresent?: false
          magicDependency?: boolean
          "data-is-present"?: boolean
      }
    | false
