import { AutoValueHandler } from "framer-motion"

const mix = (from: number, to: number, progress: number) => -progress * from + progress * to + from

const borderWidth = (axis: "x" | "y", key: string): AutoValueHandler => ({
    read: width => (width ? parseFloat(width) : 0),
    createUpdater: (values, origin: number, target: number, current, delta, treeScale) => {
        if (!(origin || target)) return

        const motionValue = values.get(key, 0)

        return p => {
            const v = mix(origin, target, p)
            current[key] = v

            const correctedWidth = v / delta[axis].scale / treeScale[axis]
            motionValue.set(correctedWidth)
        }
    },
})

const xBorder = (key: string) => borderWidth("x", key)
const yBorder = (key: string) => borderWidth("y", key)

export const autoValueHandlers = {
    borderColor: {},
    borderTopWidth: yBorder("borderTopWidth"),
    borderLeftWidth: xBorder("borderLeftWidth"),
    borderRightWidth: xBorder("borderRightWidth"),
    borderBottomWidth: yBorder("borderBottomWidth"),
}
