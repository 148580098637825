import { RenderTarget, RenderEnvironment } from "../types/RenderEnvironment"
// AssetContext and parseAssetReference must be imported as a relative path
// to prevent it from being included in the framer.api.md imports list as a module.
import { AssetContext } from "../../../../Assets/src/types/AssetResolver"
import { parseAssetReference } from "../../../../Assets/src/assetReference"
import { resolveFramerResourceURL } from "./resolveFramerResourceURL"

// This should be moved to the runtime, but currently is too dependant on
// RenderTarget and RenderEnvironment (which should also be moved to the
// runtime)

function imagePathForSize(imageName: string, size: number | undefined): string {
    // don't apply cache size for third party images
    // HOTFIX. Proper fix: regenerate sizes for third party images
    const cacheDir = size === undefined || imageName.indexOf("node_modules") === 0 ? "" : `../../.cache/images/${size}/`
    return cacheDir + imageName
}

function resolveImagePath(relativePath: string, imageBaseURL: string, target: RenderTarget): string {
    // Checks if the image base url ends with a slash.
    const endsWithSlash = imageBaseURL.endsWith("/")

    // Checks if either the image base url ends with `design/images`, or if the relative path starts with `design/images`.
    // `design/images/` will only be added to the path if it's missing on both the image base url as and the relative path.
    // This is included, because Vekter expects it to be there at all times, as well as to prevent it from appearing twice in a row,
    // resulting in an invalid path.
    const useDesignImagesPrefix =
        !imageBaseURL.endsWith("design/images" + (endsWithSlash ? "/" : "")) &&
        !relativePath.startsWith("design/images/")

    // absolute paths
    if (
        relativePath.startsWith("http://") ||
        relativePath.startsWith("https://") ||
        relativePath.startsWith("file://")
    ) {
        return relativePath
    }
    // for images from design components, their path will start with node_modules/PACKAGE_NAME/
    if (relativePath.startsWith("node_modules/")) {
        relativePath = "../../" + relativePath
    }
    if (target === RenderTarget.export) {
        return `##base64-${imageBaseURL}${useDesignImagesPrefix ? "design/images/" : ""}${relativePath}##`
    }
    return `${imageBaseURL.replace(/\/$/, "")}/${useDesignImagesPrefix ? "design/images/" : ""}${relativePath}`
}

/**
 * @internal
 * @param reference
 * @param context
 */
export function desktopAssetResolver(reference: string | undefined, context: AssetContext): string | undefined {
    if (!reference) {
        return undefined
    }

    let path: string
    const details = parseAssetReference(reference)
    if (details) {
        const { identifier, packageIdentifier } = details
        if (packageIdentifier) {
            path = `node_modules/${packageIdentifier}/${identifier}`
        } else {
            path = identifier
        }
    } else if (context.isFramerResourceURL) {
        // Framer resource url's and asset references are mutually exclusive.
        // isFramerResourceURL can be deprecated when everything has been migrated to asset references
        return resolveFramerResourceURL(reference)
    } else {
        path = reference
    }

    const { imageBaseURL } = RenderEnvironment
    const target = RenderTarget.current()
    const { size } = context
    const imagePath = imagePathForSize(path, size)
    const resolvedImage = resolveImagePath(imagePath, imageBaseURL, target)
    return resolvedImage
}
