import * as React from "react"

export * from "framer-motion"
import "./animation/Motion/addChildHack"

export {
    Scroll,
    ScrollProps,
    ScrollEvents,
    ScrollConfig,
    Page,
    PageEvents,
    PageProps,
    PageProperties,
    PageEffect,
    PageEffectInfo,
    PageAlignment,
    Draggable,
    Stack,
    StackProperties,
    StackSpecificProps,
    Navigation,
} from "./components"
export { WithNavigator } from "./components/hoc/WithNavigator"
export {
    Device,
    DeviceProperties,
    DeviceDescriptor,
    DeviceSkin,
    DeviceSkins,
    DeviceRenderer,
} from "./components/devices/Device"
export * from "./components/devices/Devices"

export { AnyInterpolation, ValueInterpolation } from "./interpolation"

export { Animatable, AnimatableObject, Cancel, DeprecatedAnimationTarget, isAnimatable } from "./animation/Animatable"
export { animate } from "./animation/animate"
export { FramerAnimation } from "./animation/FramerAnimation"
export { BezierAnimator, SpringAnimator } from "./animation/Animators"

export { FramerEvent, FramerEventListener, FramerEventSession } from "./events"

export { LineJoin, LineCap } from "./render/types/Stroke"
export { collectFiltersFromProps } from "./render/utils/filtersForNode"
export {
    getConfigFromPreviewURL as getConfigFromURL,
    getConfigFromPreviewURL,
    getConfigFromVekterURL,
} from "./render/utils/getConfigFromURL"
export { serverURL } from "./render/assetResolver"
export { memoize } from "./utils/memoize"
export { InternalID } from "./utils/internalId"
export { LinearGradientElementProperties } from "./render/utils/elementPropertiesForGradient"
export { frameFromElement, frameFromElements, dispatchKeyDownEvent, DOM } from "./render/utils/dom"
export { FillProperties } from "./render/traits/Fill"
export { FilterProperties, FilterNumberProperties } from "./render/traits/Filters"
export { BackgroundFilterProperties } from "./render/traits/BackdropFilters"
export { RadiusProperties } from "./render/traits/Radius"
export { BlendingProperties, BlendingMode, collectBlendingFromProps } from "./render/traits/Blending"
export { Background, BackgroundProperties } from "./render/traits/Background"
export { ImageFit, BackgroundImage } from "./render/types/BackgroundImage"
export { WithOpacity, withOpacity, collectOpacityFromProps } from "./render/traits/Opacity"
export { DeprecatedVisualProperties, collectVisualStyleFromProps } from "./render/style/collectVisualStyleFromProps"
export { collectBackgroundImageFromProps } from "./render/style/collectBackgroundImageFromProps"
export { WithShape, withShape } from "./render/traits/Shape"
export { BoxShadowProperties } from "./render/traits/BoxShadow"
export { gradientForShape } from "./render/utils/gradientForShape"
export { debounce } from "./render/utils/debounce"
export { throttle } from "./render/utils/throttle"
export { setImageForFill, imageUrlForFill, imageUrlForAsset, QualityOptions } from "./render/utils/imageForFill"
export { _imageURL, _imageScalingMethod } from "./render/utils/imageForFill" // for testing
export { imagePatternPropsForFill } from "./render/utils/imagePatternPropsForFill"
export {
    ComponentIdentifier,
    ComponentLoader,
    ComponentMap,
    TokenDefinition,
    TokenMap,
    isDesignDefinition,
    isOverride,
    isReactDefinition,
} from "./render/componentLoader"
export { localPackageFallbackIdentifier } from "./render/componentLoader/package"
export {
    ControlDescription,
    CompactControlsDescription,
    BooleanControlDescription,
    BaseControlDescription,
    NumberControlDescription,
    StringControlDescription,
    FusedNumberControlDescription,
    EnumControlDescription,
    SegmentedEnumControlDescription,
    ColorControlDescription,
    ImageControlDescription,
    FileControlDescription,
    ComponentInstanceDescription,
    ArrayControlDescription,
    EventHandlerControlDescription,
    TransitionControlDescription,
    ControlType,
    PropertyControls,
} from "./render/types/PropertyControls"
export { WithFractionOfFreeSpace } from "./render/traits/FreeSpace"
export { isOfAnnotatedType, annotateTypeOnStringify } from "./render/utils/annotateTypeOnStringify"
export { PathSegment } from "./render/types/PathSegment"
export { PathSegments } from "./render/types/PathSegments"
export { WithPath, withPath, WithPaths, isStraightCurve, pathDefaults, toSVGPath } from "./render/traits/Path"
export { BoxShadow, Shadow } from "./render/types/Shadow"
export { StrokeAlignment } from "./render/types/StrokeAlignment"
export { Rect } from "./render/types/Rect"
export { Size } from "./render/types/Size"
export { Line } from "./render/types/Line"
export { Point } from "./render/types/Point"
export {
    Gradient,
    LinearGradient,
    LinearGradientBase,
    RadialGradient,
    RadialGradientBase,
} from "./render/types/Gradient"
export {
    Frame,
    FrameWithMotion,
    FrameProps,
    DeprecatedFrame,
    DeprecatedFrameProperties,
    DeprecatedFrameWithEvents,
    DeprecatedFrameWithEventsProps,
    FrameLayoutProperties,
    DeprecatedCoreFrameProps,
    BaseFrameProps,
    CSSTransformProperties,
    VisualProperties,
} from "./render/presentation/Frame"
export { Layer, LayerProps, IdentityProps } from "./render/presentation/Layer"
export { SVG, SVGProperties } from "./render/presentation/SVG"
export { Text, TextProperties, TextAlignment, TextVerticalAlignment } from "./render/presentation/Text"
export { TextColorProperties } from "./render/traits/TextColor"
export { Vector, VectorProperties } from "./render/presentation/Vector"
export { VectorGroup, VectorGroupProperties } from "./render/presentation/VectorGroup"
export { ComponentContainer, ComponentContainerProperties } from "./render/presentation/ComponentContainer"
export { TextBlock, draftBlockRendererFunction } from "./render/presentation/TextBlock"
export { TransformValues } from "./render/types/TransformValues"
export {
    DefaultProps,
    ConstraintMask,
    ConstraintValues,
    ConstraintProperties,
    ConstraintPercentage,
    DimensionType,
    valueToDimensionType,
    getMergedConstraintsProps,
} from "./render/types/Constraints"

export {
    ProvideParentSize,
    NewConstraintProperties,
    calculateRect,
    ParentSizeState,
    constraintsEnabled,
} from "./render/types/NewConstraints"
export { Color, ConvertColor, ColorMixOptions } from "./render/types/Color"
export {
    ColorFormat,
    ColorRGBA,
    ColorHSLA,
    ColorHSL,
    ColorHSVA,
    ColorMixModelType,
    IncomingColor,
} from "./render/types/Color/types"
export { BorderStyle, BorderProperties } from "./render/style/BorderComponent"
export { StyleHandler, draftStyleFunction, draftStyles, getStyleForTypefaceOrSelector } from "./render/style/draft"
export { isEqual } from "./render/utils/isEqual"
// XXX: Workaround for https://github.com/microsoft/rushstack/issues/1029
//
// When fixed, replace with `export { environment } from "./utils"`, and remove
// the `environment` export from ./utils/environment.ts
export { environment } from "./utils/environment"

export { isFiniteNumber, finiteNumber } from "./render/utils/isFiniteNumber"
export { isShallowEqualArray } from "./render/utils/isShallowEqualArray"
export { roundedNumber, roundedNumberString, roundWithOffset } from "./render/utils/roundedNumber"
export { transformString } from "./render/utils/transformString"
export { isMotionValue } from "./render/utils/isMotionValue"
export { localShadowFrame, shadowForShape } from "./render/style/shadow"
export {
    PresentationTree,
    renderPresentationTree,
    convertPresentationTree,
    addServerUrlToResourceProps,
} from "./render/presentation/PresentationTree"
export { RenderTarget, executeInRenderEnvironment, setGlobalRenderEnvironment } from "./render/types/RenderEnvironment"
export { NavigationInterface, NavigationProps, NavigationConsumer } from "./components/Navigation"
export {
    NavigationTransition,
    NavigationTransitionPosition,
    NavigationTransitionSide,
    NavigationTransitionAnimation,
    NavigationTransitionAppearsFrom,
    NavigationTransitionBackdropColor,
    FadeTransitionOptions,
    PushTransitionOptions,
    ModalTransitionOptions,
    OverlayTransitionOptions,
    FlipTransitionOptions,
} from "./components/NavigationTransitions"
export {
    NavigateTo,
    NavigationLink,
    NavigationTarget,
    NavigationTransitionType,
    NavigationTransitionDirection,
} from "./render/types/NavigationLink"
export { useNavigation } from "./components/useNavigation"
export { StackDirection, StackDistribution, StackAlignment } from "./components/Stack/types"

import { collectBorderStyleForProps } from "./render/style/BorderComponent"
/** @internal */
export const styles = {
    collectBorder: collectBorderStyleForProps,
}
export { createDesignComponent, CanvasStore, PropertyTree, RenderNodeProvider } from "./render/DesignComponentWrapper"

export { CustomProperties, CustomPropertiesContext } from "./render/presentation/CustomProperties"

export { fontStore } from "./render/fonts/fontStore"
export { parseVariant } from "./render/fonts/utils"
export {
    WebFontLocator,
    Typeface,
    TypefaceSelector,
    Font,
    TypefaceSourceName,
    TypefaceSourceNames,
    TypefaceLocator,
    ReadonlyFont,
    ReadonlyTypeface,
} from "./render/fonts/types"
export { forceLayerBackingWithCSSProperties } from "./render/utils/setLayerBacked"
export { systemTypefaceName } from "./render/fonts/LocalFontSource"
export { EmptyState } from "./components/EmptyState"
export { ComponentContainerContext } from "./render/presentation/ComponentContainer"

// FIXME this should not be exported, but moved to the runtime
export { desktopAssetResolver } from "./render/assetResolver"

export { ObservableObject } from "./data/ObservableObject"
export { Data } from "./data/Data"
export { createData, DataContext } from "./data/useData"
export { Override, OverrideFunction, OverrideObject, WithOverride } from "./deprecated/WithOverride"
export { Action, ActionHandler, ActionControlDescription, ActionControls } from "./render/types/Action"
export { ActionInfo, addActionControls } from "./utils/addActionControls"
export { DataObserver, DataObserverContext } from "./deprecated/DataObserver"
export { PropertyStore } from "./data/PropertyStore"

export { loadJSON } from "./utils/network"
export { print } from "./utils/print"
export { _injectRuntime, Runtime } from "./utils/runtimeInjection"
export { addPropertyControls, getPropertyControls } from "./utils/addPropertyControls"
export { version } from "./version"

export { MotionSetup } from "./animation/Motion/MotionSetup"

import { MainLoop } from "./core/Loop"
export { MainLoop }

// Only start the loop if this is the library
if (process.env.BUILD_NAME === "framer") {
    MainLoop.start()
}
