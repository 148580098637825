import * as React from "react"
import { Rect } from "../types/Rect"
import { RenderTarget } from "../types/RenderEnvironment"
import { BackgroundImage, ImageFit } from "../types/BackgroundImage"
import { safeWindow } from "../../utils/safeWindow"
import { runtime } from "../../utils/runtimeInjection"
// isAssetReference must be imported as a relative path
// to prevent it from being included in the framer.api.md imports list as a module.
import { isAssetReference } from "../../../../Assets/src/assetReference"

/**
 * @internal
 */
export interface QualityOptions {
    frame?: Rect
    zoom: number
}

/**
 * @internal
 */
export function imageUrlForAsset(asset: string, size?: number) {
    const quality: QualityOptions = {
        zoom: 0, // is ignored
    }
    let intrinsicSize: number | null = null
    if (size !== undefined) {
        // make sure we ask for at least 1x1
        size = Math.max(1, size)
        quality.frame = { x: 0, y: 0, width: size, height: size }
        intrinsicSize = size
    }
    return _imageURL(asset, intrinsicSize, intrinsicSize, quality)
}

/**
 * @internal
 */
export function imageUrlForFill(image: BackgroundImage, quality: QualityOptions) {
    const { src, intrinsicWidth, intrinsicHeight } = image

    return _imageURL(src, intrinsicWidth || null, intrinsicHeight || null, quality)
}

const cachedImageURLs = new Set<string>()
function isImageURLCached(imageURL: string): boolean {
    if (cachedImageURLs.has(imageURL)) {
        return true
    }
    const imageElement = new Image()
    imageElement.src = imageURL
    // Because we check if the image element is complete without waiting for
    // loading, we know this image must already be in the cache
    const isCached = imageElement.complete
    if (isCached) {
        cachedImageURLs.add(imageURL)
    }
    return isCached
}

/**
 * @internal
 */
export function setImageForFill(image: BackgroundImage, quality: QualityOptions, style: React.CSSProperties) {
    const { src, pixelWidth, pixelHeight, intrinsicWidth, intrinsicHeight } = image

    const backgroundImageURL = _imageURL(src, intrinsicWidth || null, intrinsicHeight || null, quality)

    // Quality options to select the smallest variant available to use as a fallback image
    const tinyQuality: QualityOptions = {
        ...quality,
        frame: { x: 0, y: 0, width: 1, height: 1 },
    }
    const fallbackBackgroundImageURL = _imageURL(src, intrinsicWidth || null, intrinsicHeight || null, tinyQuality)

    let backgroundImage = `url("${backgroundImageURL}")`
    const addFallback = backgroundImageURL !== fallbackBackgroundImageURL && !isImageURLCached(backgroundImageURL)
    // JSDom does currently not support multiple background images, so disable this when testing: https://github.com/jsdom/jsdom/issues/1166
    if (addFallback && process.env.NODE_ENV !== "test") {
        backgroundImage += `, url("${fallbackBackgroundImageURL}")`
    }
    style.backgroundImage = backgroundImage
    style.backgroundSize = cssBackgroundSize(image.fit)
    style.backgroundRepeat = "no-repeat"
    style.backgroundPosition = "center"
    style.imageRendering = _imageScalingMethod(
        src,
        quality,
        intrinsicWidth || null,
        intrinsicHeight || null,
        pixelWidth || null,
        pixelHeight || null,
        image.fit
    )
}

function cssBackgroundSize(size: ImageFit | undefined) {
    switch (size) {
        case "fit":
            return "contain"
        case "stretch":
            return "100% 100%"
        case "fill":
        default:
            return "cover"
    }
}

function getAssetSize(intrinsicWidth: number | null, intrinsicHeight: number | null, quality: QualityOptions) {
    const dpr = safeWindow.devicePixelRatio || 1
    const bitmapMaxSize = Math.max(intrinsicWidth || 0, intrinsicHeight || 0)
    const canvasMaxSize = Math.max(
        (quality.frame && quality.frame.width) || 0,
        (quality.frame && quality.frame.height) || 0
    )

    let size: number | undefined = undefined
    const target = RenderTarget.current()
    const fullQuality = !quality.frame || target === RenderTarget.export || target === RenderTarget.preview
    const noIntrinsicSize = intrinsicWidth === null || intrinsicHeight === null // No known size means we wont have resized versions
    if (fullQuality || noIntrinsicSize) {
        size = undefined
    } else {
        // We generate the following sizes
        // 512, 1024, 2048, 4096

        // For larger bitmaps, we can get away with less quality
        const canvasPixels = canvasMaxSize * dpr
        if (canvasPixels <= 512) size = 512
        else if (canvasPixels <= 1024) size = 1024
        else if (canvasPixels <= 2048) size = 2048
        else if (canvasPixels <= 4096) size = 4096
    }
    // We limit the image size to 4096 for now. Figma does the same actually.
    if (size === undefined && bitmapMaxSize * dpr > 4096) {
        size = 4096
    }
    return size
}

/**
 * @internal
 */
export function _imageURL(
    asset: string | null,
    intrinsicWidth: number | null,
    intrinsicHeight: number | null,
    quality: QualityOptions
): string {
    if (asset === null) return ""
    if (/^\w+:/.test(asset) && !isAssetReference(asset)) return asset
    const size = getAssetSize(intrinsicWidth, intrinsicHeight, quality)
    const resolvedAsset = runtime.assetResolver(asset, { size })
    return resolvedAsset || ""
}

// Use ‘auto’ when downscaling, ‘pixelated’ when upscaling
/**
 * @internal
 */
export function _imageScalingMethod(
    imageName: string | null,
    quality: QualityOptions,
    intrinsicWidth: number | null,
    intrinsicHeight: number | null,
    pixelWidth: number | null,
    pixelHeight: number | null,
    size: ImageFit = "fill"
) {
    if (imageName === null) return "auto"
    const { frame, zoom } = quality
    if (!frame) return "auto"

    let frameWidth = frame.width
    let frameHeight = frame.height

    if (zoom > 1) {
        frameWidth *= zoom
        frameHeight *= zoom
    }
    const target = RenderTarget.current()
    if (target !== RenderTarget.export && target !== RenderTarget.preview && safeWindow.devicePixelRatio) {
        frameWidth *= safeWindow.devicePixelRatio
        frameHeight *= safeWindow.devicePixelRatio
    }

    const imageWidth = pixelWidth || intrinsicWidth || 0
    const imageHeight = pixelHeight || intrinsicHeight || 0

    if (size === "fill") {
        // in this case the image will be enlarged if either the width or height is larger, and pixels are cut off
        if (frameWidth > imageWidth || frameHeight > imageHeight) return "pixelated"
    } else {
        // in these cases the images will be enlarged only if both width and height are larger
        if (frameWidth > imageWidth && frameHeight > imageHeight) return "pixelated"
    }
    return "auto"
}
